.active_cat::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: 0;
}
.selected_count {
  content: "";
  width: 22px;
  height: 22px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
  top: -15px;
  left: -15px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}
