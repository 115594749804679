@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* width */
::-webkit-scrollbar {
  width: 7px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {

  background: #f2f2f2;
  /* display: none; */
  /* background: transparent !important; */
  
}

/* ::-webkit-scrollbar-track-piece {
  background-color: transparent;
  opacity: 0.2;
  display: none;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cb000e;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.active-page{
  border-radius: 10px;
  text-align: center;
  color: white;
  background-color: #cb000e;
  width: 40px;
  height: 29px;
}
/* body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */

.rdp {
  --rdp-cell-size: 40px; /* Size of the day cells. */
  --rdp-caption-font-size: 18px; /* Font size for the caption labels. */
  --rdp-accent-color: red !important; /* Accent color for the background of selected days. */
  --rdp-background-color: #ffe7e9 !important; /* Background color for the hovered/focused elements. */
  --rdp-accent-color-dark: #3003e1; /* Accent color for the background of selected days (to use in dark-mode). */
  --rdp-background-color-dark: gray !important; /* Background color for the hovered/focused elements (to use in dark-mode). */
  --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
  --rdp-selected-color: red; /* Color of selected day text */
}
.rdp-day {
  border-radius: 20% !important;
}
.rdp-cell {
  padding: 1px 2px !important;
}
.rdp-day_outside {
  background-color: #f9f9f9 !important;
  color: #e4e4e4 !important;
}


/* for react slick */
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #CB000E !important;
}

.slick-dots li button:before{
  font-size: 15px !important;
  color: white !important;
  opacity: 1 !important;
}

.slick-track {
  height: 620px !important;
}

/* react-responsive-carousel */

.carousel .thumb.selected, .carousel .thumb:hover {
  border: 3px solid #CB000E !important;
}